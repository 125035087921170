import "./App.css";
import OurServices from "./components/ourServices.js";
import Navbar from "./components/nav-bar";
import Banner from "./components/banner";
import BannerImg from "./assets/banner.png";
import OurMissionBackgorund from "./assets/OurMissionBackground.png";
import Footer from "./components/footer.js";
import Form from "./components/form";
import Cards from "./components/cards";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


function App() {
  const responsive = {

    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 2,
      slidesToSlide: 2 
    },
    mobile: {
      breakpoint: { max: 1000, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  }; 


  return (
    <div className="App content">
      <div id="navBar" className="mt-3  pb-md-0 mb-md-0">
        <Navbar/>
      </div>
      <div id="header" className="mb-5  mt-xl-0 pt-xl-0">
        <Banner
          title={`Safe Software, \nSafe Business`}
          image={BannerImg}
          button={"Contact Us"}
          imagePosition={"right"}
          description={"Empowering Security in Every Click"}
        />
      </div>
      <div className=" our-service-background">
      <div className="mb-0 mb-md-5">
        <Cards/>
      </div>
      <div id="infinitService" className="mb-5 pt-5">
        <OurServices/>
      </div>
      <div id="about" >
        <Banner
          title={"Ensuring Digital Safety: Our Mission"}
          description={`At SafeU, we’re dedicated to making the internet a secure place. In today’s digital age, security is not just an option, it’s a necessity. Our mission is clear: to protect users/people and businesses alike by staying one step ahead of threats.
          We take on the unseen challenges of the digital world, ensuring safety. By blending advanced technology with unmatched expertise, we turn the digital landscape into a secure space for growth and innovation. Your safety is our mission.`}
          image={OurMissionBackgorund}
          button={"Contact Us"}
          imagePosition={"left"}
        />
      </div>
      </div>
      {/* <div className="testimonial-background padding-left  mb-5 padding-right">
      <h2 className="text-center pb-5 mb-5">Testimonial</h2>
<Carousel
  swipeable={true}
  showDots={true}
  responsive={responsive}
  infinite={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
>
  <div className="card-carousel"> 1 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 2 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 3 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 4 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 5 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 6 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 7 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 8 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
  <div className="card-carousel"> 9 “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.”</div>
</Carousel>
      </div> */}

      <div id="form" className="pb-lg-0 mb-xl-0">
        <Form/>
      </div>
      <div id="footer" >
        <Footer/>
      </div>
    </div>
  );
}

export default App;




