import React from 'react';
import Logo from '../assets/logo.svg';

const Navbar = () => {

  const navigateTo = (section) => {
    window.location.href = section;
  };
  return (
    <header className="header">
      <div className="padding-left logo-image">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div>
      <div className="padding-right d-flex">
        <button onClick={() => navigateTo('#about')} className="nav-button pe-4 ">About</button>
        <button onClick={() => navigateTo('#form')} className="filled-button d-none d-sm-block">Contact Us</button>
      </div>
    </header>
  );
};

export default Navbar;

