import React from 'react';
import Logo from '../assets/logo.svg';
import Mail from '../assets/Mail.png';

function Footer() {
  return (
  <footer>
    <div className="line mb-5"></div>
    <div>

    </div>
  <div className='mb-0 mb-sm-5 pb-2 w-100 mt-2 d-sm-flex d-block justify-content-between padding-left padding-right'>  
    <div className=' pb-5 p-sm-0'><img src={Logo} alt="Logo" className="logo-image" /></div>
    <div className='d-flex align-items-center'>
      <img src={Mail} alt=''></img>  <p className='ps-3 mb-1'>contact@safeu.ai</p>
    </div>
      </div> 

<div className='padding-right padding-left pt-5'>
  <div className="line mb-5"></div>
  <div className='d-flex justify-content-between'>

  <div>
  <p>    © 2024 Safe U. All rights reserved.
 </p>

  </div>
  {/* <div className='d-flex mb-5'>
    <div className='ms-4 footer-button'>
      Privacy Policy
    </div>
    <div className='ms-4 footer-button'>
    Terms of Service
    </div>
    <div className='ms-4 footer-button'>
    Cookies Settings
    </div>
  </div> */}
  </div>

</div>
</footer>
)

}

export default Footer;
