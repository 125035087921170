import React from "react";

const OurServices = () => {

    
    const handleHover1 = () => {
      const hover2 = document.querySelector('.hover2');
      const hover3 = document.querySelector('.hover3');
      const hover4 = document.querySelector('.hover4');
        hover2.style.opacity = '0.2';
        hover2.style.transition = '0.5s';
        hover3.style.opacity = '0.2';
        hover3.style.transition = '0.5s';
        hover4.style.opacity = '0.2';
        hover4.style.transition = '0.5s';
      };

    const handleHover2 = () => {
      const hover1 = document.querySelector('.hover1');
      const hover3 = document.querySelector('.hover3');
      const hover4 = document.querySelector('.hover4');
        hover1.style.opacity = '0.2';
        hover1.style.transition = '0.5s';
        hover3.style.opacity = '0.2';
        hover3.style.transition = '0.5s';
        hover4.style.opacity = '0.2';
        hover4.style.transition = '0.5s';

      };

      const handleHover3 = () => {
        const hover1 = document.querySelector('.hover1');
        const hover2 = document.querySelector('.hover2');
        const hover4 = document.querySelector('.hover4');
        hover1.style.opacity = '0.2';
        hover2.style.opacity = '0.2';
        hover4.style.opacity = '0.2';
        hover1.style.transition = '0.5s';
        hover2.style.transition = '0.5s';
        hover4.style.transition = '0.5s';

      };

      const handleHover4 = () => {
        const hover1 = document.querySelector('.hover1');
        const hover2 = document.querySelector('.hover2');
        const hover3 = document.querySelector('.hover3');
        hover1.style.opacity = '0.2';
        hover2.style.opacity = '0.2';
        hover3.style.opacity = '0.2';
        hover1.style.transition = '0.5s';
        hover2.style.transition = '0.5s';
        hover3.style.transition = '0.5s';
      };
    
      const handleMouseOut = () => {
        const hover1 = document.querySelector('.hover1');
        const hover2 = document.querySelector('.hover2');
        const hover3 = document.querySelector('.hover3');
        const hover4 = document.querySelector('.hover4');
        hover1.style.opacity = '1';
        hover2.style.opacity = '1';
        hover3.style.opacity = '1';
        hover4.style.opacity = '1';
      };

      const handleHover1Mobile = () => {
        const hover2Mobile =  document.querySelector('.hover2-mobile');
        const hover3Mobile =  document.querySelector('.hover3-mobile');
        const hover4Mobile =  document.querySelector('.hover4-mobile');
        hover2Mobile.style.opacity = '0.2';
        hover2Mobile.style.transition = '0.5s';
        hover3Mobile.style.opacity = '0.2';
        hover3Mobile.style.transition = '0.5s';
        hover4Mobile.style.opacity = '0.2';
        hover4Mobile.style.transition = '0.5s';
      };

    const handleHover2Mobile = () => {
      const hover1Mobile =  document.querySelector('.hover1-mobile');
      const hover3Mobile =  document.querySelector('.hover3-mobile');
      const hover4Mobile =  document.querySelector('.hover4-mobile');
        hover1Mobile.style.opacity = '0.2';
        hover1Mobile.style.transition = '0.5s';

        hover3Mobile.style.opacity = '0.2';
        hover3Mobile.style.transition = '0.5s';

        hover4Mobile.style.opacity = '0.2';
        hover4Mobile.style.transition = '0.5s';

      };

      const handleHover3Mobile = () => {
        const hover1Mobile =  document.querySelector('.hover1-mobile');
        const hover2Mobile =  document.querySelector('.hover2-mobile');
        const hover4Mobile =  document.querySelector('.hover4-mobile');
        hover1Mobile.style.opacity = '0.2';
        hover2Mobile.style.opacity = '0.2';
        hover4Mobile.style.opacity = '0.2';

        hover1Mobile.style.transition = '0.5s';
        hover2Mobile.style.transition = '0.5s';
        hover4Mobile.style.transition = '0.5s';

      };

      const handleHover4Mobile = () => {
        const hover1Mobile =  document.querySelector('.hover1-mobile');
        const hover2Mobile =  document.querySelector('.hover2-mobile');
        const hover3Mobile =  document.querySelector('.hover3-mobile');
        hover1Mobile.style.opacity = '0.2';
        hover2Mobile.style.opacity = '0.2';
        hover3Mobile.style.opacity = '0.2';
        hover1Mobile.style.transition = '0.5s';
        hover2Mobile.style.transition = '0.5s';
        hover3Mobile.style.transition = '0.5s';
      };
    
      const handleMouseOutMobile = () => {
        const hover1Mobile =  document.querySelector('.hover1-mobile');
        const hover2Mobile =  document.querySelector('.hover2-mobile');
        const hover3Mobile =  document.querySelector('.hover3-mobile');
        const hover4Mobile =  document.querySelector('.hover4-mobile');
        hover1Mobile.style.opacity = '1';
        hover2Mobile.style.opacity = '1';
        hover3Mobile.style.opacity = '1';
        hover4Mobile.style.opacity = '1';
      };



  return (
    <div className="text-center">
      <div className="mb-5 pb-5">
        <h2>Our Services</h2>
      </div>
      <div className="display-none-1340 ">
        <div className="w-100   d-flex justify-content-around">
          <div className="chartsvg d-flex">
            <div onMouseOver={handleHover1} onMouseOut={handleMouseOut} id="hover1" className="ms-3 b-5 mt-5 pt-5 hover1 w-100">
               <svg
                width="36"
                height="48"
                viewBox="0 0 36 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-1 "
              >
                <path
                  d="M18 0.000976562L0 8.96783V30.0667C7.11366e-08 34.8406 1.89642 39.4189 5.27208 42.7946C8.64773 46.1703 13.2261 48.0667 18 48.0667C22.7739 48.0667 27.3523 46.1703 30.7279 42.7946C34.1036 39.4189 36 34.8406 36 30.0667V8.96783L18 0.000976562ZM28.245 40.9717L18 35.8681V39.2102L25.5541 42.9734C23.2784 44.2987 20.694 45.0017 18.0605 45.0116C15.427 45.0216 12.8373 44.3383 10.5516 43.0303C8.26587 41.7223 6.3647 39.8357 5.03914 37.5601C3.71357 35.2845 3.0103 32.7002 3 30.0667V10.8152L18 3.34283L33 10.8152V16.4402L18 8.96768V12.3095L33 19.7819V25.4069L18 17.9345V21.2764L33 28.7486V30.0667C33.0007 31.4293 32.814 32.7856 32.445 34.0973L18 26.9012V30.2431L31.3428 36.89C30.5513 38.4178 29.5035 39.7984 28.245 40.9717Z"
                  fill="#DDFF0D"
                />
                
              </svg> 
                <p className="mt-0 mt-xl-5 pt-5 mb-0 lg hover-title"> <b className="xl">Cybersecurity</b>  <br></br> Posture Assessment</p>
                <p className="mt-5 pt-5 ms-5 lg ps-3 w-75 hover-text">Understand your risk profile and get a blueprint for enhancing your security posture</p>
            </div>
            <div onMouseOver={handleHover2} onMouseOut={handleMouseOut} className="w-100 mt-5 pt-5 hover2">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-2"
              >
                <g clipPath="url(#clip0_36_1757)">
                  <path
                    d="M32 43.5H8C7.20435 43.5 6.44129 43.1839 5.87868 42.6213C5.31607 42.0587 5 41.2957 5 40.5V7.5C5 6.70435 5.31607 5.94129 5.87868 5.37868C6.44129 4.81607 7.20435 4.5 8 4.5H32V7.5H8V40.5H32V43.5Z"
                    fill="#DDFF0D"
                  />
                  <path d="M26 13.5H23V34.5H26V13.5Z" fill="#DDFF0D" />
                  <path d="M44 13.5H41V34.5H44V13.5Z" fill="#DDFF0D" />
                  <path d="M35 13.5H32V34.5H35V13.5Z" fill="#DDFF0D" />
                </g>
                <defs>
                  <clipPath id="clip0_36_1757">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mt-5 lg pt-5 hover-title"> <b className="xl">Detection</b> <br></br> Of Data Leaks</p>
                 <p className="mt-5 pt-5 ms-5 lg ps-3  w-75 hover-text">See what the bad guys can see and get a detailed report on how to fix it</p>
            </div>
            <div onMouseOver={handleHover3} onMouseOut={handleMouseOut} className="w-100 mt-5 pt-5 hover3">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-3"

              >
                <g clipPath="url(#clip0_36_1726)">
                  <path
                    d="M33.5 38.8306C35.1569 38.8306 36.5 37.4874 36.5 35.8306C36.5 34.1737 35.1569 32.8306 33.5 32.8306C31.8431 32.8306 30.5 34.1737 30.5 35.8306C30.5 37.4874 31.8431 38.8306 33.5 38.8306Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M45.1653 35.2178C44.2384 32.8567 42.6391 30.8196 40.5655 29.3589C38.4919 27.8981 36.0352 27.0779 33.5 27C30.9648 27.0779 28.5081 27.8981 26.4345 29.3589C24.3609 30.8196 22.7616 32.8567 21.8346 35.2178L21.5 36L21.8346 36.7822C22.7616 39.1433 24.3609 41.1804 26.4345 42.6411C28.5081 44.1019 30.9648 44.9221 33.5 45C36.0352 44.9221 38.4919 44.1019 40.5655 42.6411C42.6391 41.1804 44.2384 39.1433 45.1653 36.7822L45.5 36L45.1653 35.2178ZM33.5 42C32.3133 42 31.1533 41.6481 30.1666 40.9888C29.1799 40.3295 28.4108 39.3925 27.9567 38.2961C27.5026 37.1997 27.3838 35.9933 27.6153 34.8295C27.8468 33.6656 28.4182 32.5965 29.2574 31.7574C30.0965 30.9182 31.1656 30.3468 32.3295 30.1153C33.4933 29.8838 34.6997 30.0026 35.7961 30.4567C36.8925 30.9108 37.8295 31.6799 38.4888 32.6666C39.1481 33.6533 39.5 34.8133 39.5 36C39.4982 37.5908 38.8655 39.1158 37.7407 40.2407C36.6158 41.3655 35.0908 41.9982 33.5 42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M38 15H6.5C5.70461 14.9992 4.94203 14.6828 4.3796 14.1204C3.81717 13.558 3.50083 12.7954 3.5 12V6C3.50083 5.20461 3.81717 4.44203 4.3796 3.8796C4.94203 3.31717 5.70461 3.00083 6.5 3H38C38.7954 3.00083 39.558 3.31717 40.1204 3.8796C40.6828 4.44203 40.9992 5.20461 41 6V12C40.9992 12.7954 40.6828 13.558 40.1204 14.1204C39.558 14.6828 38.7954 14.9992 38 15ZM6.5 6V12H38V6H6.5Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M18.5 42H6.5V36H18.5V33H6.5C5.70463 33.0009 4.9421 33.3173 4.37969 33.8797C3.81728 34.4421 3.50091 35.2046 3.5 36V42C3.50091 42.7954 3.81728 43.5579 4.37969 44.1203C4.9421 44.6827 5.70463 44.9991 6.5 45H18.5V42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M42.5 18H11C10.2046 18.0009 9.4421 18.3173 8.87969 18.8797C8.31728 19.4421 8.00091 20.2046 8 21V27C8.00091 27.7954 8.31728 28.5579 8.87969 29.1203C9.4421 29.6827 10.2046 29.9991 11 30H18.5V27H11V21H42.5L42.501 24H45.5V21C45.4991 20.2046 45.1827 19.4421 44.6203 18.8797C44.0579 18.3173 43.2954 18.0009 42.5 18Z"
                    fill="#DDFF0D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_1726">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
                
              </svg>
              <p className="mt-5 lg pt-5 hover-title"> <b className="xl">Prevention</b> <br></br> Of Data Loss</p>
              <p className="mt-5 pt-5 ms-5 lg ps-3 w-75 hover-text">Automate your security checks using state of the art technology and get our support in the process</p>

            </div>
            <div onMouseOver={handleHover4} onMouseOut={handleMouseOut} className="w-100 mt-5 pt-5 hover4 me-2">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-4"
              >
                <g clipPath="url(#clip0_36_1722)">
                  <path
                    d="M42.5 24V33H6.5V9H17V6H6.5C5.70435 6 4.94129 6.31607 4.37868 6.87868C3.81607 7.44129 3.5 8.20435 3.5 9V33C3.5 33.7956 3.81607 34.5587 4.37868 35.1213C4.94129 35.6839 5.70435 36 6.5 36H18.5V42H12.5V45H36.5V42H30.5V36H42.5C43.2957 36 44.0587 35.6839 44.6213 35.1213C45.1839 34.5587 45.5 33.7956 45.5 33V24H42.5ZM27.5 42H21.5V36H27.5V42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M27.5 27.0002H27.485C27.1617 26.9968 26.8482 26.889 26.5911 26.693C26.334 26.4969 26.1471 26.2231 26.0584 25.9122L23.3684 16.5002H17V13.5002H24.5C24.8259 13.5002 25.143 13.6063 25.4032 13.8026C25.6635 13.9989 25.8527 14.2746 25.9423 14.588L27.5535 20.2263L32.0668 5.55886C32.164 5.25411 32.3548 4.98778 32.6122 4.79771C32.8695 4.60765 33.1801 4.50353 33.5 4.50016C33.8159 4.49549 34.125 4.59273 34.3813 4.77748C34.6376 4.96224 34.8276 5.22466 34.923 5.52586L37.581 13.5002H45.5V16.5002H36.5C36.1852 16.5002 35.8783 16.4011 35.6229 16.217C35.3675 16.0329 35.1765 15.7731 35.077 15.4745L33.5571 10.9145L28.9334 25.9415C28.839 26.248 28.6489 26.5162 28.391 26.7067C28.133 26.8973 27.8207 27.0001 27.5 27.0002Z"
                    fill="#DDFF0D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_1722">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mt-5 lg pt-5 hover-title"> <b className="xl">Healing</b> <br></br> Process</p>
              <p className="mt-5 pt-5 ms-5 lg ps-3 w-75 hover-text">Our custom solution, designed to elevate the security of your company to the next level</p>

            </div>
          </div>
        </div>
      </div>

      <div className="infinitServicesBackground"></div>
    <div className="d-flex justify-content-center display-block-1340">
      <div >
        <div className="card-our-services mb-5">
        <div onMouseOver={handleHover1Mobile} onMouseOut={handleMouseOutMobile} className="hover1-mobile mt-4">
               <svg
                width="36"
                height="48"
                viewBox="0 0 36 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-1 "
              >
                <path
                  d="M18 0.000976562L0 8.96783V30.0667C7.11366e-08 34.8406 1.89642 39.4189 5.27208 42.7946C8.64773 46.1703 13.2261 48.0667 18 48.0667C22.7739 48.0667 27.3523 46.1703 30.7279 42.7946C34.1036 39.4189 36 34.8406 36 30.0667V8.96783L18 0.000976562ZM28.245 40.9717L18 35.8681V39.2102L25.5541 42.9734C23.2784 44.2987 20.694 45.0017 18.0605 45.0116C15.427 45.0216 12.8373 44.3383 10.5516 43.0303C8.26587 41.7223 6.3647 39.8357 5.03914 37.5601C3.71357 35.2845 3.0103 32.7002 3 30.0667V10.8152L18 3.34283L33 10.8152V16.4402L18 8.96768V12.3095L33 19.7819V25.4069L18 17.9345V21.2764L33 28.7486V30.0667C33.0007 31.4293 32.814 32.7856 32.445 34.0973L18 26.9012V30.2431L31.3428 36.89C30.5513 38.4178 29.5035 39.7984 28.245 40.9717Z"
                  fill="#DDFF0D"
                />
                
              </svg> 
                <p className="mt-5  mb-0 hover-title"> <b className="xl">Cybersecurity</b>  <br></br> Posture Assessment</p>
                <p className="mt-5 me-4 hover-text">Understand your risk profile and get a blueprint for enhancing your security posture</p>
            </div>
        </div>
        <div className="card-our-services mb-5">
        <div onMouseOver={handleHover2Mobile} onMouseOut={handleMouseOutMobile} className="hover2-mobile mt-4">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-2"
              >
                <g clipPath="url(#clip0_36_1757)">
                  <path
                    d="M32 43.5H8C7.20435 43.5 6.44129 43.1839 5.87868 42.6213C5.31607 42.0587 5 41.2957 5 40.5V7.5C5 6.70435 5.31607 5.94129 5.87868 5.37868C6.44129 4.81607 7.20435 4.5 8 4.5H32V7.5H8V40.5H32V43.5Z"
                    fill="#DDFF0D"
                  />
                  <path d="M26 13.5H23V34.5H26V13.5Z" fill="#DDFF0D" />
                  <path d="M44 13.5H41V34.5H44V13.5Z" fill="#DDFF0D" />
                  <path d="M35 13.5H32V34.5H35V13.5Z" fill="#DDFF0D" />
                </g>
                <defs>
                  <clipPath id="clip0_36_1757">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mt-5  hover-title"> <b className="xl">Detection</b> <br></br> Of Data Leaks</p>
              <p className="mt-5 me-4 hover-text">See what the bad guys can see and get a detailed report on how to fix it</p>
            </div>
        </div>
        <div className="card-our-services mb-5">
        <div onMouseOver={handleHover3Mobile} onMouseOut={handleMouseOutMobile} className="hover3-mobile mt-4">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-3"

              >
                <g clipPath="url(#clip0_36_1726)">
                  <path
                    d="M33.5 38.8306C35.1569 38.8306 36.5 37.4874 36.5 35.8306C36.5 34.1737 35.1569 32.8306 33.5 32.8306C31.8431 32.8306 30.5 34.1737 30.5 35.8306C30.5 37.4874 31.8431 38.8306 33.5 38.8306Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M45.1653 35.2178C44.2384 32.8567 42.6391 30.8196 40.5655 29.3589C38.4919 27.8981 36.0352 27.0779 33.5 27C30.9648 27.0779 28.5081 27.8981 26.4345 29.3589C24.3609 30.8196 22.7616 32.8567 21.8346 35.2178L21.5 36L21.8346 36.7822C22.7616 39.1433 24.3609 41.1804 26.4345 42.6411C28.5081 44.1019 30.9648 44.9221 33.5 45C36.0352 44.9221 38.4919 44.1019 40.5655 42.6411C42.6391 41.1804 44.2384 39.1433 45.1653 36.7822L45.5 36L45.1653 35.2178ZM33.5 42C32.3133 42 31.1533 41.6481 30.1666 40.9888C29.1799 40.3295 28.4108 39.3925 27.9567 38.2961C27.5026 37.1997 27.3838 35.9933 27.6153 34.8295C27.8468 33.6656 28.4182 32.5965 29.2574 31.7574C30.0965 30.9182 31.1656 30.3468 32.3295 30.1153C33.4933 29.8838 34.6997 30.0026 35.7961 30.4567C36.8925 30.9108 37.8295 31.6799 38.4888 32.6666C39.1481 33.6533 39.5 34.8133 39.5 36C39.4982 37.5908 38.8655 39.1158 37.7407 40.2407C36.6158 41.3655 35.0908 41.9982 33.5 42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M38 15H6.5C5.70461 14.9992 4.94203 14.6828 4.3796 14.1204C3.81717 13.558 3.50083 12.7954 3.5 12V6C3.50083 5.20461 3.81717 4.44203 4.3796 3.8796C4.94203 3.31717 5.70461 3.00083 6.5 3H38C38.7954 3.00083 39.558 3.31717 40.1204 3.8796C40.6828 4.44203 40.9992 5.20461 41 6V12C40.9992 12.7954 40.6828 13.558 40.1204 14.1204C39.558 14.6828 38.7954 14.9992 38 15ZM6.5 6V12H38V6H6.5Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M18.5 42H6.5V36H18.5V33H6.5C5.70463 33.0009 4.9421 33.3173 4.37969 33.8797C3.81728 34.4421 3.50091 35.2046 3.5 36V42C3.50091 42.7954 3.81728 43.5579 4.37969 44.1203C4.9421 44.6827 5.70463 44.9991 6.5 45H18.5V42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M42.5 18H11C10.2046 18.0009 9.4421 18.3173 8.87969 18.8797C8.31728 19.4421 8.00091 20.2046 8 21V27C8.00091 27.7954 8.31728 28.5579 8.87969 29.1203C9.4421 29.6827 10.2046 29.9991 11 30H18.5V27H11V21H42.5L42.501 24H45.5V21C45.4991 20.2046 45.1827 19.4421 44.6203 18.8797C44.0579 18.3173 43.2954 18.0009 42.5 18Z"
                    fill="#DDFF0D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_1726">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
                
              </svg>
              <p className="mt-5 hover-title"> <b className="xl">Prevention</b> <br></br> Of Data Loss</p>
              <p className="mt-5 me-4 hover-text">Automate your security checks using state of the art technology and get our support in the process</p>
            </div>
        </div>
        <div className="card-our-services mb-5">
        <div onMouseOver={handleHover4Mobile} onMouseOut={handleMouseOutMobile} className="hover4-mobile mt-4">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon-4"
              >
                <g clipPath="url(#clip0_36_1722)">
                  <path
                    d="M42.5 24V33H6.5V9H17V6H6.5C5.70435 6 4.94129 6.31607 4.37868 6.87868C3.81607 7.44129 3.5 8.20435 3.5 9V33C3.5 33.7956 3.81607 34.5587 4.37868 35.1213C4.94129 35.6839 5.70435 36 6.5 36H18.5V42H12.5V45H36.5V42H30.5V36H42.5C43.2957 36 44.0587 35.6839 44.6213 35.1213C45.1839 34.5587 45.5 33.7956 45.5 33V24H42.5ZM27.5 42H21.5V36H27.5V42Z"
                    fill="#DDFF0D"
                  />
                  <path
                    d="M27.5 27.0002H27.485C27.1617 26.9968 26.8482 26.889 26.5911 26.693C26.334 26.4969 26.1471 26.2231 26.0584 25.9122L23.3684 16.5002H17V13.5002H24.5C24.8259 13.5002 25.143 13.6063 25.4032 13.8026C25.6635 13.9989 25.8527 14.2746 25.9423 14.588L27.5535 20.2263L32.0668 5.55886C32.164 5.25411 32.3548 4.98778 32.6122 4.79771C32.8695 4.60765 33.1801 4.50353 33.5 4.50016C33.8159 4.49549 34.125 4.59273 34.3813 4.77748C34.6376 4.96224 34.8276 5.22466 34.923 5.52586L37.581 13.5002H45.5V16.5002H36.5C36.1852 16.5002 35.8783 16.4011 35.6229 16.217C35.3675 16.0329 35.1765 15.7731 35.077 15.4745L33.5571 10.9145L28.9334 25.9415C28.839 26.248 28.6489 26.5162 28.391 26.7067C28.133 26.8973 27.8207 27.0001 27.5 27.0002Z"
                    fill="#DDFF0D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_36_1722">
                    <rect
                      width="48"
                      height="48"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="mt-5   hover-title"> <b className="xl">Healing</b> <br></br> Process</p>
              <p className="mt-5 me-4   hover-text">Our custom solution, designed to elevate the security of your company to the next level</p>
            </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default OurServices;
