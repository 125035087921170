import React, { useState } from 'react';
import Background from '../assets/footerBackground.png';

function Form() {
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [country, setCountry] = useState('');
    const [message, setMessage] = useState('');
    // const [termsAccepted, setTermsAccepted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Prepare form data
        const formData = new URLSearchParams();
        formData.append('fullName', fullName);
        formData.append('email', email);
        formData.append('phoneNumber', phoneNumber);
        formData.append('companyName', companyName);
        formData.append('country', country);
        formData.append('message', message);

        // const 
        //     maxFullNameLength = 100,
        //     maxPhoneNumberLength = 25,
        //     maxEmailLength = 100,
        //     maxCompanyNameLength = 100,
        //     maxCountryLength = 50,
        //     maxMessageLength = 1000
           
    
        try {
            const response = await fetch('https://safeu.ai/api/v1/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: formData.toString(), // Convert form data to string
            });
    
            if (response.ok) {
                // Request successful
                console.log('Form submitted successfully');
                // Reset form fields
                setFullName('');
                setPhoneNumber('');
                setEmail('');
                setCompanyName('');
                setCountry('');
                setMessage('');
            } else {
                // Request failed
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='d-lg-flex   pt-5 justify-content-between border-top-1'>
            <div>
                <div className='pb-0 mb-0 pb-lg-5 mb-lg-5 padding-left'>
                    <h3>
                        We're here to help
                    </h3>
                    <p>We look forward to connecting with you to answer your questions </p>


                </div>
                <img className='display-none-1200' src={Background} alt=''></img>
            </div>
            <form onSubmit={handleSubmit} className='form-background pb-5 mt-5 mb-5 mb-md-0 mt-md-0 padding-left-1200 padding-right'>
                <div className='mb-4 16'>
                    <label htmlFor="fullName">Full Name*</label> 
                    <input
                        type="text"
                        id="fullName"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        className='input-form'
                    />
                </div>
                
                <div className='d-flex mb-4'>

                    <div className='pe-2 w-100'>
                        <label htmlFor="email">Email*</label> 
                        <input
                            type="email"
                            id="email"
                            value={email}
                            className='input-form'
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div  className='ps-3 w-100'>
                        <label htmlFor="phoneNumber">Phone Number</label> 
                        <input
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            className='input-form'
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                </div>
                <div className='d-flex mb-4'>
                    <div  className='pe-2 w-100'>
                        <label htmlFor="companyName">Company Name</label>
                        <input
                            type="text"
                            id="companyName"
                            value={companyName}
                            className='input-form'
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </div>
                    <div  className='ps-3 w-100'>
                        <label htmlFor="country">Country/Region</label>
                        <input
                            type="text"
                            id="country"
                            value={country}
                            className='input-form'
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        value={message}
                        className='form-control'
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                </div>
                {/* <div className='mb-4'>
                    <div className='d-flex align-items-center'>
                    <input
                        type="checkbox"
                        id="termsAccepted"
                        htmlFor="termsAccepted"
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <label  htmlFor="termsAccepted" className="custom-checkbox"></label>
                    <span className='ps-3 14'> I accept the Terms</span>
                    </div>

                </div> */}
                <div>
                    <button className='submit-button' type="submit">Submit</button>
                </div>
            </form>
        </div>

    );
}

export default Form;
