import React from 'react';

const Banner = ({ title, description, image, button, imagePosition }) => {

    
    const navigateToForm = () => {
        // Redirect to the form page
        window.location.href = '#form';
        
    };

    return (
        imagePosition === 'right' ? <div className="banner pb-4">
            <div className="banner-content pb-5 mb-5 pt-5   padding-left z-index-1">
                <h1 className="banner-title">{title}</h1>
                <p className="xl">{description}</p>
                <button onClick={navigateToForm} className="filled-button">{button}</button>
            </div>
            <div className='image-container'>
            <img src={image} alt="Banner" className='pb-5 mb-5 banner-image '/>

            </div>
        </div> : <div className="banner">
        <img src={image} alt="Banner" className='display-none-1200 '/>
            <div className="banner-content banner-background padding-left-1200 padding-right z-index-1">
                <h2 className="banner-title">{title}</h2>
                <p className="banner-description text-justify">{description}</p>
                <button onClick={navigateToForm}  className="filled-button">{button}</button>
            </div>
        </div>  
    );
};

export default Banner;