import React from "react";

const Cards = () => {
  return (
    <div className="padding-left padding-right">

      <div className="d-lg-flex cards">
        
        <div className="me-0 mb-5 mb-lg-0s me-lg-5">
          <div className="card-1 mb-5 d-flex align-items-center">
            <div className="me-5">
              <span className="card-title">78</span><span className="card-subtitle">%</span>
            </div>
            <div className="xl">
              More cyber attacks occurred in 2023 compared to 2022
            </div>
          </div>
          <div className="card-1 d-flex mb-5 d-lg-none align-items-center">
            <div className="me-5 justify-content-center"><span className="card-title">4.5</span><span className="card-subtitle">M</span></div>
            <div><span className="xl">Average cost per cyberattack</span></div>
          </div>
          <div className="card-2"> 
            <span className="xl">It's expected for <b>2024</b>  to keep this trend upwards, particularly with the new AI technologies being used by threat actors.</span>
          </div>
        </div>
        
        <div>
          <div className="card-2 xl mb-5">
          Do you want to prevent that damage and be safe? We have the solution
            for a <b className="color-safe-software">safe software, safe business. SafeU.</b>
          </div>
          <div className="card-1 d-none d-md-flex align-items-center">
            <div className="me-5 pe-0 pe-xl-3 justify-content-center"><span className="card-title">4.5</span><span className="card-subtitle">M</span></div>
            <div><span className="xl ms-0 ms-xl-3">Average cost per cyberattack</span></div>
          </div>
        </div>
      
      </div>

    </div>
  );
}

export default Cards;
